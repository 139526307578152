import { Controller } from 'stimulus'

import pinIcon from '../../../images/pin_icon'

export default class extends Controller {
  static targets = ['canvas', 'location']

  static values = {
    loopItinerary: Boolean
  }

  connect () {
    super.connect()

    if (window.google) {
      this.initMap()
    } else {
      window.addEventListener("google-maps-loaded", this.initMap.bind(this))
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.canvasTarget, this._mapOptions())
    this.bounds = new google.maps.LatLngBounds()
    this.infowindow = new google.maps.InfoWindow({ pixelOffset: new google.maps.Size(10, -30) })
    this.markers = []

    const itinerarySteps = [];

    this.locationTargets.forEach(locationTarget => {
      const locationLat = locationTarget.dataset.lat
      const locationLng = locationTarget.dataset.lng
      if (!locationLat || !locationLng) return

      const locationPoint = new google.maps.LatLng(locationLat, locationLng);

      this._extendMap(locationPoint)
      this._addMarker(locationPoint, locationTarget)
      itinerarySteps.push(locationPoint);
    })

    if (this.loopItineraryValue && itinerarySteps.length > 2)
      itinerarySteps.push(itinerarySteps[0])

    this._createItinerary(itinerarySteps)
  }

  _extendMap (newLocationPoint) {
    this.bounds.extend(newLocationPoint)
    this.map.fitBounds(this.bounds)
  }

  _onMarkerClick (marker, locationTarget) {
    this.infowindow.setPosition(marker.getPosition());
    this.infowindow.setContent(locationTarget.innerHTML);
    this.infowindow.open(this.map);
  }

  _addMarker (locationPoint, locationTarget) {
    const marker = new google.maps.Marker({
      map: this.map,
      position: locationPoint,
      title: locationTarget.dataset.title,
      icon: {
        url: pinIcon,
        size: new google.maps.Size(25, 42),
        anchor: new google.maps.Point(3, 41)
      }
    });

    google.maps.event.addListener(marker, 'click', () => this._onMarkerClick(marker, locationTarget));

    this.markers.push(marker);
  }

  _createItinerary (itinerarySteps) {
    const itinerary = new google.maps.Polyline({
      path: itinerarySteps,
      geodesic: true,
      icons: [{
        icon: { path: 'M 0,-1 0,2', strokeOpacity: 1, scale: 2 },
        offset: '0',
        repeat: '10px'
      }],
      strokeColor: '#f21862',
      strokeOpacity: 0,
      strokeWeight: 1
    })

    itinerary.setMap(this.map);
  }

  _mapOptions () {
    return {
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      scrollwheel: false,
      //scaleControl: false,
      //draggable: false,
    }
  }
}
