import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "content", "label"]

  connect () {
    this.buttonTargets[0].classList.add('active')
    this.contentTargets[0].classList.add('active')
  }

  toggleActive (e) {
    this.resetActiveButton()
    e.target.classList.add('active')

    this.contentTargets.forEach((element) => {
      e.target.dataset.tabName === element.dataset.tabName ? element.classList.toggle('active') : element.classList.remove('active')

      if (this.hasLabelTarget) {
        this.labelTarget.innerHTML = e.target.innerHTML;
      }
    })
  }

  resetActiveButton () {
    this.buttonTargets.forEach((element) => {
      element.classList.remove('active')
    })
  }
}
