import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryList']

  hideCountryList () {
    this.countryListTarget.querySelectorAll('.list-countries').forEach((elem) => {
      elem.classList.remove('is-active')
      elem.classList.add('hidden')
    })
  }
}
